.customer-detail-card {
  margin-bottom: 16px;
  background-color: white;
  padding: 16px;
}

.customer-detail-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 16px;
}