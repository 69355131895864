.homescreen {
  font-family: "Lato";
}

.homescreen__header {
  height: 400px;
  display: flex;
  gap: 16px;
}

.homescreen__announcement-title {
  font-size: 24px;  
  font-family: "Lato-Black";
  padding-left: 14px;
}

.homescreen__title {
  font-size: 24px;
  font-family: "Lato-Black";
  line-height: 29px;  
}

.homescreen__announcement-view-more {
  font-size: 14px;
  font-weight: 600;
  color: #ee7623;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
}

.homescreen__dashboard {
  margin-top: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.homescreen__topbanner_left{
  flex-grow: 1;
  width: 60%;
}

.homescreen__topbanner_right{
  width: 40%;
}

.homescreen__main_content{
  
}

.homescreen__cards_container{
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  row-gap: 16px;  
}

.cards_container{
  width: 22%;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  
  .homescreen__header {    
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: auto !important;
    margin-bottom: 40px;
  }

  .homescreen__topbanner_left{
    flex-grow: 1;
    width: 100%;
  }

  .homescreen__topbanner_right{
    width: 100%;
  }

  .homescreen__title {
    font-size: 22px;
    font-family: "Lato-Black";
    line-height: 27px;  
  }

  .homescreen__cards_container{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    row-gap: 16px;  
  }

  .cards_container{
    width: 30%;
  }

}
