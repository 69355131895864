.filter-button__container {
    padding: 8px 12px;    
    border: 1px solid #D8D7D6;    
    color: #183028;
    background-color: white;    
}

.selected_dot {
    height: 10px;
    width: 10px;
    background-color: white;
    display: inline-block;
    border-radius: 50%;
    margin-right:8px ;    
}

.active {
    background-color: #EE7623;
    color: #FFFF;    
}