.app-steps_container {
  background-color: #fff;
  padding: 24px;
  margin-right: 16px;
}

.ant-steps-item-icon .ant-steps-icon {
  position: relative;
  top: -2px !important;
  line-height: 1;
}

.ant-steps-item-tail::after {
  background-color: #f0f2f4 !important;
}

.app-steps-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 16px;
}
