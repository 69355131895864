.app-content-field-title2 {
  font-size: 14px;
  font-weight: 400;
  /* width: 50%; */
}

.app-content-field2 {
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
}

.xl {
  font-size: 21px;
}

.app-content-field-tag2 {
  color: #9599b6;
  width: 50%;
}
