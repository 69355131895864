.dropdown-filter {
  font-family: "Lato";
  border: 1px solid rgba(224, 224, 224, 1);
  width: 240px;
  height: 52px;
  background: white;
}

.dropdown-filter__label {
  color: rgba(146, 146, 157, 1);
  font-size: 14px;
  width: 160px;
}

.dropdown-filter__item {
  font-size: 14px;
  font-weight: 600;
}

.ant-select-selection-placeholder {
  color: rgba(24, 48, 40, 1) !important;
}

.ant-picker-range-separator {
  align-items: center;
  padding: 0 8px;
  margin-top: -2px !important;
  line-height: 1;
}

.ant-select {
  font-weight: 800 !important;
  font-size: 14px !important;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-placeholder {
  font-weight: 800;
  font-size: 14px !important;
}

.ant-picker-input > input {
  font-size: 14px !important;
}

.ant-select-item-option-content {
  white-space: break-spaces !important;
  word-break: break-word !important;
}
