.announcements-detail {
  font-family: "Lato";
  padding: 0 12%;
}

.announcements-detail__timestamp {
  font-size: 10px;
  font-weight: 600;
  color: #183028;
}

.announcements-detail__title {
  font-size: 32px;
  font-weight: 800;
  color: #183028;
}

.announcements-detail__ {
  font-size: 14px;
  font-weight: 600;
  color: #183028;
}
