input:focus {
  -webkit-box-shadow: 0 0 0 0 white inset !important;
  box-shadow: 0 0 0 0 white inset !important;
}

.searching_options_container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  max-height: 330px;
  overflow-y: auto;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.searching_icon_prefix {
  background: url("../../../../public/assets/icons/Search.svg") no-repeat;
  background-position: center left;
  background-size: 20px;
}

.searching_option {
  flex: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 14px;
  font-size: 14px;
  font-weight: 600;
  color: #183028;
}

.searching_result_text {
  font-size: 14px;
  font-weight: 400;
  color: #183028;
}

.searching_result_text span {
  font-weight: 700;
}

.searching_result_text:hover {
  color: #183028;
}

.searching_result_no_data {
  color: #9599b6;
  font-weight: 600;
  font-size: 14px;
  justify-content: center;
  text-align: center;
  margin-top: 57px;
  margin-bottom: 24px;
}

.select_deselect_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select_deselect {
  font-size: 14px;
  font-weight: 600;
  color: #ee7623;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
  text-decoration: underline;
  text-underline-offset: 4px;
}

.notice_modal_content {
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 6px;
}

.close_button {
  margin-bottom: 10px;
}

.notice_dialog_title {
  font-family: "OpenSans-Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
}

.notice_dialog_desc {
  font-family: "OpenSans-Regular";
  font-style: normal;
  margin-top: 16px;
  font-size: 16px;
  line-height: 22px;
}

.button_container {
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
}

.confirm_button {
  font-family: "OpenSans-Bold";
  font-size: 16px;
  padding-top: 6px;
  padding-bottom: 5px;
  text-align: center;
  border: 2px solid #ff0000;
  width: 240px;
  border-radius: 20px;
  background-color: transparent;
  margin-right: 8px;
}

.cancel_button1 {
  font-family: "OpenSans-Bold";
  font-size: 16px;
  padding-top: 6px;
  padding-bottom: 5px;
  text-align: center;
  background-color: #ff0000;
  color: white;
  width: 240px;
  border-radius: 20px;
  margin-right: 8px;
}

/* .cancel_button :hover {} */

.mr-20 {
  margin-left: -24px;
}

@media screen and (max-width: 767px) {
  .modal-content {
    width: 100%;
    height: auto !important;
    border-radius: 8px !important;
  }
}
