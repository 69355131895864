.sidebar {
  padding-top: 70px;
  position: fixed !important;
  height: 100%;
  background-color: white !important;  
}

.sidebar-content {
  padding-top: 80px;
  background-color: white !important;
}

.ml_mc_w_c {
  margin-left: 80px;
}

.ml_mc_w_nc {
  margin-left: 250px;
}

.zindex_sidebar {
  z-index: 0;
}

.ant-menu-inline.ant-menu-root .ant-menu-item>.ant-menu-title-content,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title>.ant-menu-title-content {
  font-family: "Lato" !important;
  font-size: 14px;
  font-weight: 400;
  background-color: white !important;
}

ul.ant-menu {
  padding-inline-start: 0px !important;    
}

.ant-menu>.ant-menu-item:hover,
.ant-menu>.ant-menu-item-only-child:hover,
.ant-menu>.ant-menu-item-active,
/* .ant-menu > .ant-menu-submenu-active, */
/* .ant-menu > .ant-menu-submenu-active > div:hover > span, */
/* .ant-menu > .ant-menu-item-open, */
/* .ant-menu > .ant-menu-submenu-open, */
/* .ant-menu > .ant-menu-item-selected, */
/* .ant-menu > .ant-menu-item-selected a, */
.ant-menu>.ant-menu-submenu-open span:hover,
.ant-menu>.ant-menu-submenu-title span:hover,
.ant-menu-title-content:hover

/* .ant-menu > .ant-menu-submenu-selected */
  {
  background-color: white !important;
  color: #ee7623 !important;
  font-weight: 800 !important;
  fill: #ee7623;
}

.ant-menu>.ant-menu-submenu-selected,
.ant-menu>.ant-menu-submenu-selected:hover,
.ant-menu>.ant-menu-submenu-title {
  color: black;
}

.ant-menu>.ant-menu-item-selected a,
.ant-menu>.ant-menu-item-selected>svg>path,
.ant-menu>.ant-menu-item:hover>svg>path,
.ant-menu>.ant-menu-submenu:hover>svg>path,
.ant-menu-submenu-title:hover>svg>path,
.ant-menu>.ant-menu-submenu-active>svg>path {
  color: #ee7623 !important;
  font-weight: 700 !important;
  fill: #ee7623 !important;
}

a:hover {
  color: #ee7623 !important;
  font-weight: 700 !important;
  fill: #ee7623 !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: white !important;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 0 !important;
  background-color: white !important;
}

.ant-menu-item {
  background-color: white !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 23/2px;
  padding-bottom: 23/2px;
}

.logo_bank_container {
  margin-top: -70px;
  padding-top: 20px;
  padding-left: 24px;
  margin-bottom: 20px;
  display: none;
}

.logo_bank_icon_sidebar{
  height: 35px;
}

@media screen and (max-width: 1024px) {
  .ml_mc_w_c {
    margin-left: 80px;
  }

  .ml_mc_w_nc {
    margin-left: 80px;
  }

  .zindex_sidebar {
    z-index: 100;
  }

  .collapse-icon {
    width: 16px;
    height: 24px;
    background-color: #fafafa;
    border-radius: 4px 0px 0px 4px;
  }

  .disableDiv {
    pointer-events: none;
    opacity: 0.5; 
  }

  .ant-layout{
    background: rgba(0,0,0,0.6) !important;
  }

  .sidebar {    
    width: 345px !important;
  }
 
  .logo_bank_container{
    display: block;
  }
}