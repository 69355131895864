.ant-table-thead .ant-table-cell {
  background-color: #183028;
  color: #ffffff;
  font-family: "Lato";
  font-weight: 600;
  font-size: 14px;
  border: 1px solid #183028;      
}

/* .highlight-bottom-border > td {
  border-bottom: solid 1px black !important
}

.highlight-top-border > td {
  border-top: solid 2px black !important;
  border-bottom: solid 2px #6d9eff !important
} */

.ant-table-tbody .ant-table-cell {
  font-family: "Lato";
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  background-color: #ffffff;  
}

/* .anticon svg {
  color: white !important;
} */

.ant-pagination-item-active:hover,
.ant-pagination-item-active,
.ant-pagination-item {
  border: 0px;
  border-color: transparent !important;
}

.ant-table-pagination.ant-pagination {
  background-color: #ffffff !important;
  margin: 0 !important;
  padding: 16px !important;
}

.ant-pagination-total-text {
  /* vertical-align: bottom !important; */
  position: absolute;
  left: 0;
  font-weight: 400;
  padding-left: 16px;
  font-family: "Lato";
  font-size: 14px;
}

.ant-pagination-options {
  display: none !important;
}

.ant-pagination-item-1 {
  content: "3";
  /* color: red; */
}

.page_title_paggination {
  font-size: 14px;
  font-weight: 400;
  margin-right: -60px;
}

.ant-pagination-prev {
  padding-right: 45px !important;    
}

.ant-pagination-next {
  padding-left: 45px !important;
}

.page_total_paggination {
  font-size: 14px;
  font-weight: 400;
  width: 100px;
  margin-left: -60px;
  pointer-events:none;
}

@media only screen and (max-width: 1024px) {
  .ant-table-thead .ant-table-cell {         
      white-space: nowrap;
      text-overflow: ellipsis;                    
  }
}



