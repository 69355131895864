.selected_button_description {
    font-size: 14px;
    font-weight: 400;
}

.notifications_container {
    padding: 24px 16px;
    background-color: white;
}

.notification_dot {
    height: 36px;
    width: 36px;
    background-color: white;
    border-radius: 50%;
    /* border: 1px solid #e5eaee; */
    display: inline-block;
    margin: 5px;
    text-align: center;
    padding-top: 6px;
    cursor: pointer;
    font-weight: 600;
}

.empty_state_notification {
    height: 147px;
    background-color: white;
    align-items: center;    
}

.notification_title {
    font-size: 16px;
    font-weight: 400;   
}

.notification_title span {
    cursor: pointer;
    font-weight: 600;    
}

.notification_title b {
    cursor: pointer;
    font-weight: 600;       
}

.notification_desc span {
    cursor: pointer;     
}

.notification_desc {
    font-size: 13px;
    font-weight: 400;   
}

