.dashboard-header {
  margin-top: 32px;
  font-family: "Lato";
}

.dashboard-header__title {
  font-size: 21px;
  font-weight: 600;
}

.dashboard-header__view-more {
  font-size: 14px;
  font-weight: 600;
}
