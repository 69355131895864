.channel_selection_container {
  position: absolute;
  text-align: left;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px; 
  min-width: 60%;
}

.channel_selection_title {
  font-family: 'Lato-Black';
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  margin-left: 20px;
}

.channel_selection_desc {
  font-family: 'Lato';
  font-size: 16px;
  line-height: 19px;
  color: #9599B6;
}

.channel_selection_list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
}

.channel_selection_item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* padding: 32px 16px;
  gap: 10px;
  border-radius: 8px;
  height: 200px;
  cursor: pointer;
  max-width: 40%; */
}


