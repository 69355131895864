.profile__container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  margin-top: 32px;
  color: #171725;
}

.profile__detail {
  margin-top: 32px;
  background-color: white;
  padding: 72px;
}

.profile__detail-title {
  font-weight: 800;
  font-size: 24px;
}

.profile {
  font-family: "Lato";
  margin-top: 48px;
}

.profile__customer {
  padding: 16px;
}

.profile__customer-detail {
  margin-bottom: 16px;
}

.profile__customer-detail-title {
  font-size: 14px;
  font-weight: 400;
}

.profile__customer-detail-content {
  margin: 16px 0;
  font-size: 14px;
  font-weight: 400;
}

.profile__customer-detail-desc {
  color: #183028;
  font-weight: 600;
  font-size: 16px;
}

.xl {
  font-size: 21px;
}

.profile__customer-detail-tag {
  color: #171725;
  font-weight: 700;
  font-size: 18px;
}

.title {
  color: #183028;
  font-weight: 600;
}

.desc {
  font-size: 12px;
}
