.print_download_container {
  margin-top: 30px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}
.text_icon_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 11px;
}

.print_icon {
  width: 15px;
  height: 15px;
}

.button_style_dialog {
  font-family: "OpenSans-Bold";
  font-size: 14px;
  text-align: center;
  border: 2px solid #e5e5e5;
  border-radius: 20px;
  background-color: "transparent";
  color: #000000;
  width: 140px;
}
