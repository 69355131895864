.app-content-field-title4 {
  font-size: 14px;
  font-weight: 400;
  /* width: 50%; */
}

.app-content-field4 {
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
}

.xl {
  font-size: 21px;
}

.app-content-field-tag4 {
  width: 50%;
  font-weight: 400;
  font-size: 18px;
}
