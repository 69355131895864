.fund-detail-card {
  margin-bottom: 16px;

  padding: 16px;
}

.fund-detail-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 16px;
}

.fund-detail-value {
  font-size: 16px;
  font-weight: 600;
}

.fund-detail-disclaimer {
  font-weight: 400;
  size: 16px;
}
