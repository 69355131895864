.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.header__profile {
  cursor: pointer;
}

nav {
  font-weight: 550;
  height: 80px;
  /* position: fixed; */

  /* top: 0;
  left: 0; */
  width: 100%;
  box-sizing: border-box;
  padding: 23.03px 50.42px;
  background-color: #fff;
  z-index: 1;
  border-bottom: 1px solid #e0e0e0;
}

ul {
  list-style: none;
}

.menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px !important;
}

.user-info {
  height: 32px;
  width: 100%;
}

.user-info__name {
  font-size: 12px;
  font-weight: 600;
  font-family: "Lato";
}

.user-info__role {
  font-size: 10px;
  font-weight: 400;
  color: #828282;
  font-family: "Lato";
}

.logo_bank_icon {
  height: 35px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {  
}
