.dialog-filter-container {
  cursor: pointer;
}

.dialog-filter {
  border: 1px solid rgba(224, 224, 224, 1);
  width: 235px;
  height: 52px;
  background: white;
}

.dialog-filter__label {
  color: #183028;
  font-size: 14px;
  font-weight: 700;
  width: 100%;
  font-family: "Lato";
}

.dialog-filter__item {
  font-size: 14px;
  font-weight: 600;
}

.ant-select-selection-placeholder {
  color: rgba(24, 48, 40, 1) !important;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .dialog-filter {    
    width: 235;        
  }
}
