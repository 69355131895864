.ant-select-selection-item {
  font-weight: 600;
  font-size: 14px;
}

.header-title {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  font-family: "Lato";
}

.header-title__icon {
  cursor: pointer;
}

.header-title__title {
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 8px;
}

.header-title__description {
  font-size: 14px;
  font-weight: 400;
}

.header-title__button {
  border: 1px solid black;
  background-color: #fff;
}

.header-title__dropdown-toggle {
  /* width: 451px; */
  background-color: #fff;
  border: 1px solid #e0e0e0;
  padding: 8px;
}

.header-title__dropdown-item {
  font-size: 14px;
  font-weight: 700;
  border-color: #e0e0e0;
  justify-content: center;
  align-items: center;
}

.header-title__dropdown-toggle-info {
  font-size: 14px;
  font-weight: 400;
  color: #92929d;
  margin-right: 8px;
}

.header-title__radio-input {
  display: flex;
  height: 40px;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
  color: white;
  font-weight: 700;
  size: 14px;
}

.header-title__radio-container {
  border: 1px solid #d8d7d6;
}

.ant-radio-checked .ant-radio-inner {
  background-color: #ee7623 !important ;
  border-color: white !important;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: white !important;
}

.ant-radio:hover .ant-radio-inner {
  border-color: white !important;
}

.ant-btn:hover,
.ant-btn:focus {
  color: black !important;
}
