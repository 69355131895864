.claim-status {  
  height: 99px;

  padding: 16px;
  gap: 16px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  font-family: "Lato";
}

.claim-status-skeleton {  
  height: 99px;
  padding: 16px;
  gap: 16px;
  background: #F0F0F0;
  border: 1px solid #e0e0e0;
  font-family: "Lato";
}

.claim-status-mini {
  padding: 8px;
  background: #ffffff;
  font-family: "Lato";
  display: flex;
  align-items: center;
  border: 1px solid #e0e0e0;
}

.claim-status-mini-skeleton {
  padding: 8px;
  background: #ffffff;

  font-family: "Lato";
  display: flex;
  align-items: center;
  margin-right: 16px;
  border: 1px solid #f0f0f0;
}

.claim-status-mini__image {
  padding-right: 16px;
}

.claim-status-mini__data-count {
  padding-right: 6px;
  font-size: 18px;
  font-weight: 700;
}

.claim-status-mini-skeleton__data-count-skeleton {
  padding-right: 6px;
  font-size: 18px;
  font-weight: 700;
  color: #00000000;
}

.claim-status__title {
  font-size: 14px;
  font-weight: 600;
}

.claim-status__title-skeleton {
  font-size: 14px;
  font-weight: 600;
  color: #00000000;
}

.claim-status__data-count {
  font-size: 28px;
  font-weight: 700;
}

.pending {
  background-color: rgba(255, 200, 37, 0.2);
}
.outstanding {
  background-color: rgba(62, 174, 218, 0.2);
}
.in-force {
  background-color: rgba(61, 213, 152, 0.2);
}
.contract-lapsed {
  background-color: rgba(255, 200, 37, 0.2);
}
.lapsed {
  background-color: rgba(224, 224, 224, 0.2);
}
.reinstatement {
  background-color: rgba(238, 118, 35, 0.2);
}
.submitted {
  background-color: rgba(62, 174, 218, 0.2);
}
.processing {
  background-color: rgba(255, 200, 37, 0.2);
}
.approved {
  background-color: rgba(61, 213, 152, 0.2);
}
.claim-paid {
  background-color: rgba(238, 118, 35, 0.2);
}
.rejected {
  background-color: rgba(252, 90, 90, 0.2);
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  
}
