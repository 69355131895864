.nav_bar_continue_button_enabled {
  border-radius: 20px;
  background-color: #ff0000;
  color: white;
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  padding-left: 32px;
  padding-right: 32px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.nav_bar_continue_button_enabled_dark {
  border-radius: 20px;
  background-color: white;
  color: #ff0000;
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  padding-left: 32px;
  padding-right: 32px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.nav_bar_continue_button_enabled {
  padding-left: 32px;
  padding-right: 32px;
}

.nav_bar_continue_button_enabled_dark {
  padding-left: 32px;
  padding-right: 32px;
}

.nav_bar_continue_button_cancel {
  background: #ffffff;
  border: 2px solid #ff0000;
  border-radius: 20px;
  color: #000000;
  height: 40px;
  width: 117px;
  text-align: center;
  font-family: "OpenSans-Bold";
  font-size: 14px;
  text-align: center;
}

.nav_bar_continue_button_disabled {
  border-radius: 20px;
  background-color: white;
  color: #cccccc;
  font-size: 16px;
  height: 40px;
  font-weight: normal;
  padding-left: 32px;
  padding-right: 32px;
  border: 2px solid #cccccc;
}

.nav_bar_continue_button_disabled {
  padding-left: 32px;
  padding-right: 32px;
}

.nav_bar_continue_button_disabled {
  padding-left: 32px;
  padding-right: 32px;
}

.arrowright_button {
  width: 16px;
  height: 11px;
  margin-left: 11px;
}

@media screen and (max-width: 767px) {
  .content_button {
    display: flex;
  }

  .nav_bar_continue_button_enabled {
    border-radius: 20px;
    background-color: #ff0000;
    color: white;
    font-size: 16px;
    font-weight: bold;
    height: 40px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    padding-left: 16px;
    padding-right: 16px;
  }

  .nav_bar_continue_button_enabled_dark {
    border-radius: 20px;
    background-color: white;
    color: #ff0000;
    font-size: 16px;
    font-weight: bold;
    height: 40px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    padding-left: 16px;
    padding-right: 16px;
  }

  .nav_bar_continue_button_disabled {
    border-radius: 20px;
    background-color: white;
    color: #cccccc;
    font-size: 16px;
    height: 40px;
    font-weight: normal;
    padding-left: 16px;
    padding-right: 16px;
    border: 2px solid #cccccc;
  }

  .arrowright_button {
    width: 16px;
    height: 11px;
  }
}

@media screen and (max-width: 350px) {
  .nav_bar_continue_button_enabled {
    border-radius: 18px;
    background-color: #ff0000;
    color: white;
    font-size: 12px;
    font-weight: bold;
    height: 36px;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-left: 8px;
    padding-right: 8px;
  }

  .nav_bar_continue_button_disabled {
    border-radius: 18px;
    background-color: white;
    color: #cccccc;
    font-size: 12px;
    height: 36px;
    font-weight: normal;
    padding-left: 8px;
    padding-right: 8px;
    border: 2px solid #cccccc;
  }
}

.button {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 600;
  border: 1px solid black;
  background: white;
  padding: 6px;
  width: 300px;
  height: auto;  
  align-self: center;
  justify-content: center;
}
