.announcement {
  width: 100%;
}

.announcement__container {
  padding:16px;
  width: 100%;
  gap: 16px;
  background: #ffffff;
  font-family: "Lato";
  cursor: pointer;
}

.announcement__title {
  font-size: 16px;
  font-weight: 600;  
}
.announcement__content {
  font-size: 13px;
  font-weight: 400;  
}
.announcement__timestamp {
  font-size: 10px;
  font-weight: 600;
  color: #92929d;
  margin-top: 10px;  
}

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}

.announcement_image_container{
  max-width: 270px;
}

@media only screen and (max-width: 1024px) {
  .announcement_image_container{
    max-width: 210px;
  }
}
