.businessOpportunities__status {
  margin-top: 16px;
  margin-bottom: 32px;
}

.businessOpportunities_result {
  font-weight: 700;
  font-size: 14px;
  line-height: 16.8px;
}
