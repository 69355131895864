.image_style {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

.ant-carousel .slick-dots {
  padding-bottom: 16px;
}

.ant-carousel .slick-dots li button {
  border-radius: 100% !important;
  width: 8px !important;
  height: 8px !important;
  border-color: white !important;
}

.carousel__wrapper {
  position: relative;
}

.carousel__content {
  padding-top: 70px;
  position: absolute;
  padding-bottom: 10px;
  bottom: 0px;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.000),
    rgba(0, 0, 0, 0.400),
    rgba(0, 0, 0, 0.850),
    rgba(0, 0, 0, 0.850),
    rgba(0, 0, 0, 0.850)
  );
  padding-left: 16px;
  padding-right: 16px;
  font-family: "Lato";
  color: white;
  width: 100%;
}

.carousel__date {
  font-size: 10px;
  font-weight: 600;
}

.carousel__title {
  font-size: 23px;
  font-family: "Lato-Black";
  font-weight: 800;
}

.carousel__readmore {
  font-size: 10px;
  cursor: pointer;
  margin-top: 14px;
  font-weight: 600;
}

.carousel__desc {
  font-size: 14px;
  font-weight: 400;
}

  .ant-carousel .slick-slide img {
    object-fit: cover !important;
  }

@media only screen and (max-width: 1024px) {
  .carousel__content {    
    width: 68vw;
  }
}
