.terms-modal-content {
  height: 100%;
  margin-top: 10px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
}

.pdf_viewer_container {
  flex: 1;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 68vh;
  justify-content: center;
  align-self: center;
}

.terms_and_condition_close_button {
  margin-bottom: 20px;
  margin-top: 10px;
  cursor: pointer;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
}

/* .react-pdf__Page__canvas {
  min-height: 100% ! important;
  max-width: 100%! important;
} */

@media only screen and (max-width: 425px) {
  .modal-dialog {
    height: 100%;
  }

  .modal-content {
    height: 100%;
  }
}

@media screen and (max-width: 767px) {
  .modal-content {
    width: 100%;
    height: 100% !important;
    border-radius: 0px !important;
  }

  .pdf_viewer_container {
    height: auto;
    max-height: 100%;
    margin-left: -40px;
    margin-right: -40px;
    margin-top: -10px;
    justify-content: center;
    align-self: center;
  }

}