.news-card {
  padding: 16px;
  width: "100%";
  line-height: 15.6px;

  background: #ffffff;
  font-family: "Lato";
  cursor: pointer;
  max-height: 100px;
}

.news-card__title {
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 18px;
}

.news-card__short-content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* maximum number of rows */
  -webkit-line-clamp: 2; 
}

.news-card__content-wrapper {
  font-size: 13px;
  font-weight: 400;
}
.news-card__timestamp {
  font-size: 10px;
  font-weight: 600;
  color: #92929d;
}

