.login_error {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: red;
  margin-top: 10px;
  height: 10px;
}

.login__logo-image {
  width: 145px;
  height: 72px;
  margin-left: 64px;
}

.login__wrapper {
  font-family: "Lato";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  width: 480px;
}

.login__logo-container {
  margin-bottom: 32px;
}

.login__header {
  font-size: 24px;
  font-weight: 800;
  padding-bottom: 32px !important;
  font-family: "Lato-Black";
}

.login__forms {
  width: 480px;
  padding: 72px;
  background-color: #ffffff;
  margin-right: 25px;
}

.login__checkbox-description,
.login__forgot-password {
  font-weight: 700;
  color: #ee7623;
  font-size: 14px;
}

.login_description {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
}

.login__input {
  margin-bottom: 16px;
}

.ant-input {
  font-weight: 400;
  font-size: 14px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-radius: 3px;
  width: 20px !important;
  height: 20px !important;
  background-color: #ee7623 !important;
  border-color: #ee7623 !important;
}

.ant-checkbox .ant-checkbox-inner {
  border-radius: 3px;
  width: 20px !important;
  height: 20px !important;

  border-color: #b5b5be !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled),
.ant-input-affix-wrapper-focused {
  border-color: #b5b5be !important;
  box-shadow: none !important;
}

input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

.ant-picker .ant-picker-input span.ant-picker-suffix {
  margin-left: 1px;
  margin-right: 10px;
  order: -1 !important;
} 
