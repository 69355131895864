.green {
  background-color: rgba(61, 213, 152, 0.1);
  color: #3dd598;
}

.purple {
  background-color: rgba(188, 80, 255, 0.1);
  color: #bc50ff;
}

.blue {
  background-color: rgba(80, 181, 255, 0.1);
  color: #3eaeda;
}

.yellow {
  background-color: rgba(255, 197, 66, 0.1);
  color: #ffc825;
}

.orange {
  background-color: rgba(255, 151, 74, 0.1);
  color: #fbaf62;
}

.grey {
  background-color: rgba(116, 116, 116, 0.1);
  color: #747474;
}

.red {
  background-color: rgba(252, 90, 90, 0.1);
  color: #fc5a5a;
}

.table-status {
  padding: 4px;
  font-weight: 600;
  font-size: 14px;
  width: fit-content;
}
