.proposal__status {
  margin-top: 16px;
  margin-bottom: 32px;
  flex-wrap: wrap;
  gap: 16px;
}

.proposal__result {
  font-weight: 700;
  font-size: 14px;
  line-height: 16.8px;
}

.ant-pagination-item-active a {
  font-weight: 800 !important;
}

.search_criteria_container{
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
}

.ant-table-wrapper {
  overflow-x: scroll !important;
  -ms-overflow-style: none;
    scrollbar-width: none;
}

.ant-table-wrapper::-webkit-scrollbar {
  display: none;
}

.dropdown_list_container{
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
}
.search_criteria_container{
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  row-gap: 8px;
}
