.app-content-field-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 16px;
}

.app-content-field {
  margin: 12px 0;
  font-size: 14px;
  font-weight: 400;
}

.xl {
  font-size: 21px;
}

.app-content-field-tag {
  color: #9599b6;
}
