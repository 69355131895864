.proposal-outstanding {
  font-family: "Lato";
  margin-top: 48px;
}

.proposal-outstanding__customer {
  padding: 16px;
}

.proposal-outstanding__customer-detail {
  margin-bottom: 16px;
}

.proposal-outstanding__customer-detail-title {
  font-size: 24px;
  font-weight: 700;
}

.proposal-outstanding__customer-detail-content {
  margin: 16px 0;
  font-size: 14px;
  font-weight: 400;
}

.proposal-outstanding__customer-detail-desc {
  color: #183028;
  font-weight: 600;
  font-size: 16px;
}

.xl {
  font-size: 21px;
}

.proposal-outstanding__customer-detail-tag {
  color: #9599b6;
}

.title {
  color: #183028;
  font-weight: 600;
}

.desc {
  font-size: 12px;
}
